/*
 * @Author: wangqs 
 * @description 个人担保
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2021-10-08 14:46:08
 */
<template>
  <div class="page-box box">
    <div class="title-box box">
      <img
        class="icon"
        src="@/assets/img/contract-icon-b.png"
        alt=""
      >
      个人担保信息
    </div>
    <div class="container-box box">
      <van-field
        v-model="form.name"
        required
        input-align="right"
        label="担保人姓名"
        placeholder="请输入担保人姓名"
      />

      <van-field
        v-model="form.idCardNo"
        required
        input-align="right"
        label="身份证号"
        placeholder="请输入身份证号码"
      />

      <van-field
        v-model="form.bankCardNo"
        required
        input-align="right"
        label="银行卡号"
        type="number"
        placeholder="请输入银行卡号"
      />

      <van-field
        v-model="form.mobile"
        required
        type="number"
        input-align="right"
        label="担保人手机号"
        max-length="11"
        placeholder="请输入手机号"
      />

      <van-field
        readonly
        required
        input-align="right"
        name="area"
        :value="addrStr"
        label="家庭地址"
        placeholder="点击选择省市区"
        @click="showArea = true"
      />
      <van-popup
        v-model="showArea"
        position="bottom"
      >
        <van-area
          :area-list="areaList"
          @confirm="onConfirmAddr"
          @cancel="showArea = false"
        />
      </van-popup>

      <van-field
        v-model="detailAddr"
        required
        input-align="right"
        label="详细地址"
        placeholder="请输入详细地址"
      />

      <van-field
        v-model="form.email"
        input-align="right"
        label="邮箱"
        placeholder="请输入联系人邮箱"
      />
    </div>

    <van-button
      class="submit-btn"
      color="#51B464"
      round
      type="info"
      @click="submit"
    >
      提交
    </van-button>
    <form
      :action="faceData.action || 'https://dsptest.cpcn.com.cn/DSPLivenessWeb/InterfaceVI'"
      name="faceFormC"
      style="opacity: 0;position: absolute;left: -2000px;top:0;"
      method="POST"
    >
      <div>
        <input
          type="text"
          name="message"
          placeholder="message"
          :value="faceData.message"
        />
      </div>
      <div>
        <input
          type="text"
          name="signature"
          placeholder="signature"
          :value="faceData.signature"
        />
      </div>
      <div>
        <input
          id="dgtlEnvlp"
          type="text"
          name="dgtlenvlp"
          placeholder="dgtlenvlp"
          :value="faceData.dgtlenvlp"
        />
      </div>
      <div>
        <input
          id="signSN"
          type="text"
          name="signSN"
          placeholder="signSN"
          :value="faceData.signSN"
        />
      </div>
      <div>
        <input
          id="encryptSN"
          type="text"
          name="encryptSN"
          placeholder="encryptSN"
          :value="faceData.encryptSN"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
export default {
  name: 'PersonGuarantee',
  components: {
  },
  data () {
    return {
      areaList,
      form: {
        cardType: '1',
        type: '1',
        name: '',
        idCardNo: '',
        mobile: '',
        bankCardNo: '',
        addr: '',
        email: ''
      },
      orderInfo: {},
      showArea: false,
      addrStr: '',
      detailAddr: '',
      faceData: {}
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    async submit () {
      let data = this.form
      data.addr = this.addrStr.replace(/\//g, ' ') + ' ' + this.detailAddr
      data.orderNo = this.$route.query.id || ''
      console.log(data)
      if (this.filterData()) {
        this.$toast.loading({
          message: '加载中',
          forbidClick: true,
          duration: 0
        });
        let res = await this.$http.orgApi.orgGuaranteeAdd(data)
        console.log(res, 'res')
        if (res.code === 200) {
          this.$toast.clear()
          // this.$toast.success('新增成功')
          // this.$router.back()
          let resB = await this.$http.orderApi.orderFaceOrgParams({id: res.msg.id, orderNo: this.$route.query.id || ''})
          if (resB.code === 200) {
            this.faceData = resB.msg || {}
            this.$nextTick(() => {
              document.faceFormC && document.faceFormC.submit()
            })
          }
        }
      }
    },
    filterData () {
      console.log(this.form.addr)
      if (!this.form.name) {
        this.$toast('请完善担保人姓名')
        return false
      } else if (!this.form.idCardNo || this.form.idCardNo.length < 15) {
        this.$toast('请填写正确的身份证号,一般是15或18位')
        return false
      } else if (!this.form.bankCardNo) {
        this.$toast('请完善银行卡号')
        return false
      } else if (!this.addrStr) {
        this.$toast('请选择省市县')
        return false
      } else if (!this.detailAddr) {
        this.$toast('请完善详细地址')
        return false
      } else if (!this.form.mobile || this.form.mobile.length !== 11) {
        this.$toast('请填写正确的手机号')
        return false
      } else {
        return true
      }
    },
    onConfirmAddr (values) {
      this.addrStr = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/');
      this.orderInfo.provinceNo = values[0].code
      this.orderInfo.cityNo = values[1].code
      this.orderInfo.areaNo = values[2].code
      this.orderInfo.provinceName = values[0].name
      this.orderInfo.cityName = values[1].name
      this.orderInfo.areaName = values[2].name
      this.showArea = false;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
